import { useEffect, useState } from "react";
import { Alert, Button, Card, Col, FormCheck, FormControl, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import manageApi from "../../../app/services/secured/manage";
import { GatewayAPI, GatewayFormsProps, SendInBlueList, SENDINBLUE_GATEWAY } from "../../../types";
import { FormInputGroup, LoadingBlock } from "../../misc/Blocks";
import { BtnIconLoad } from "../../misc/Buttons";
import SimpleForm from "../../misc/SimpleForm";
import { PendingLeadsButton } from "../AccountExports";

const API_KEYS: Record<string, string> = {
    SENDINGBLUE_API_KEY: "Genelead (défaut)",
    SENDINGBLUE_API_KEY_AIDE_ACTION: "Aide & Action",
    SENDINGBLUE_API_KEY_FRANCE_GENEROSITE: "France Générosité",
    SENDINGBLUE_API_KEY_FOLLEREAU: "Fondation Raoul Follereau",
    SENDINGBLUE_API_KEY_UNADEV: "Unadev",
}

const SendInBlueForm: React.FC<GatewayFormsProps> = gateway => {
    const apiKeys = Object.keys(API_KEYS as Record<string, string>);

    const [isNew, setIsNew] = useState(true);
    const [setContactList, contactList] = manageApi.useLazyGetSendInBlueListQuery();
    
    const [folder, setFolder] = useState(true);
    const [listId, setListId] = useState<number>(0);
    const [folderId, setFolderId] = useState<number>(0);
    const [selectedList, setSelectedList] = useState<Array<SendInBlueList>>([]);
    const [listType, setListType] = useState('exists');
    const config = gateway.config as SENDINBLUE_GATEWAY;

    const [apiKey, setApiKey] = useState(config?.api_key ? config.api_key : apiKeys[0]);
    const [gatewayAPI, callGatewayAPI] = manageApi.useHandleFormGatewayMutation();

    useEffect(() => {
        setContactList({ apiToken: apiKey });
        if (gateway.id) setIsNew(false);
    }, [callGatewayAPI.isSuccess])

    useEffect(() => {
        if (contactList.isSuccess) {
            const node = contactList.data.lists.find(l => l.id === parseInt(config?.list_id));
            if (node) {
                setSelectedList([node] as Array<SendInBlueList>);
                setListId(node.id);
            }
        } 
    }, [contactList.isSuccess, config])

    return <>
        {
            gateway && <>
                <SimpleForm onSubmit={(json) => {
                    gatewayAPI(isNew
                        ? { company: gateway.company, form: gateway.form, gateway: '', data: json as SENDINBLUE_GATEWAY } as GatewayAPI
                        : { company: gateway.company, form: gateway.form, gateway: gateway.id, data: json as SENDINBLUE_GATEWAY } as GatewayAPI
                    )
                }}>
                    <FormControl type='hidden' name='gateway_code' value='sendinblue' />
                    <FormControl type='hidden' name='config[api_key]' value={apiKey} />
                    <Card className='mt-3'>
                        <Card.Header>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center'>
                                    <FormCheck id='activate_api' type='switch' label='Activer export' name='enabled' defaultChecked={gateway?.enabled} />
                                    {!isNew && gateway.id && <PendingLeadsButton company_id={gateway.company} form_id={gateway.form} gateway_id={gateway.id} />}
                                </div>
                                <h6 className='text-end m-0'>SendinBlue</h6>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <h6>Accès API</h6>
                            <div className='d-flex'>
                                {apiKeys.map(k => <FormCheck key={Math.random()} type='radio' name='apiAccess' className='ms-2'
                                    label={API_KEYS[k]}
                                    checked={apiKey === k}
                                    onChange={() => { setContactList({ apiToken: k }); setApiKey(k); setListId(0); setSelectedList([]); }} />)}
                            </div>
                            <Tabs defaultActiveKey={'0'} className='mt-3'>
                                <Tab title='Liste de contact existante' eventKey={'0'} onClick={() => setListType('exists')}>
                                    <LoadingBlock data={contactList}>
                                        {
                                            contactList.isSuccess &&
                                            contactList.data && <>
                                                <Typeahead size="sm" key={parseInt(config?.list_id) + Math.random()} id='contact-list' labelKey={'name'} 
                                                    defaultSelected={selectedList}
                                                    options={contactList?.data?.lists && contactList?.data?.lists as Array<SendInBlueList>}
                                                    placeholder='Rechercher une liste de contact'
                                                    onChange={(sel) => {
                                                        if (sel[0]) {
                                                            const list = sel[0] as { id: number };
                                                            setListId(list.id);
                                                            setSelectedList(sel as Array<SendInBlueList>);
                                                        } else {
                                                            setSelectedList([]);
                                                        }
                                                    }}
                                                />

                                                {listType === 'exists' && <FormControl type='hidden' name='config[list_id]' value={listId} />}
                                            </>
                                        }
                                        {
                                            selectedList.length === 0 && <>
                                                <Alert className='mt-1'>Aucune liste de contact sélectionnée</Alert>
                                            </>
                                        }
                                    </LoadingBlock>
                                </Tab>
                                <Tab title='Nouvelle liste de contact' eventKey={'2'} onClick={() => setListType('new')} >
                                    <InputGroup>
                                        {folder &&
                                            contactList.data && <>
                                                <Typeahead size='sm' id='list-folders' labelKey={'name'}
                                                    options={contactList?.data?.folders as Array<SendInBlueList>}
                                                    placeholder='Rechercher un dossier'
                                                    onChange={(sel) => {
                                                        if (sel[0]) {
                                                            const folder = sel[0] as { id: number };
                                                            setFolderId(folder.id);
                                                        }
                                                    }}
                                                />
                                                {folder && listType === 'new' && <FormControl type='hidden' name='config[folder_id]' value={folderId} />}
                                            </>
                                        }

                                        {!folder && <>
                                            <FormControl size='sm' type='text' name='config[folder_name]' placeholder='Nom du nouveau dossier' />
                                        </>}

                                        <Button size='sm' variant="outline-secondary" onClick={() => setFolder(true)}>Dossier existant</Button>
                                        <Button size='sm' variant="outline-secondary" onClick={() => setFolder(false)}>Nouveau dossier</Button>
                                    </InputGroup>

                                    {listType === 'new' && <>
                                        <FormInputGroup label='Nom de la liste' className='mt-2'>
                                            <FormControl size='sm' type='text' placeholder='nom' name='config[list_name]' />
                                        </FormInputGroup>
                                    </>}
                                </Tab>
                            </Tabs>
                            <Row className='mt-3'>
                                <h6>Attributs</h6>
                                <Col>    
                                    <FormControl size='sm' type='text' placeholder='Prénom' name="config[attributes][firstname]" defaultValue={config?.attributes?.firstname} />
                                </Col>
                                <Col>
                                    <FormControl size='sm' type='text' placeholder='Nom' name="config[attributes][lastname]" defaultValue={config?.attributes?.lastname} />
                                </Col>
                                <Col>
                                    <FormControl size='sm' type='text' placeholder='Téléphone' name="config[attributes][phone]" defaultValue={config?.attributes?.phone} />
                                </Col>
                            </Row>

                            <div className='mt-3 text-end'>
                                <BtnIconLoad data={callGatewayAPI}>Enregister</BtnIconLoad>
                            </div>
                        </Card.Body>
                    </Card>
                </SimpleForm>
            </>
        }
    </>
}

export default SendInBlueForm;